import './style.css';
import './style-dev.css';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import {Map, View} from 'ol';
import OSM from 'ol/source/OSM.js';
import {Vector as VectorSource} from 'ol/source';
import {fromLonLat} from 'ol/proj';
import {Icon, Style} from 'ol/style.js';
import Overlay from 'ol/Overlay.js';
import {DragPan, MouseWheelZoom, defaults} from 'ol/interaction.js';
import {platformModifierKeyOnly} from 'ol/events/condition.js';
import {FullScreen, defaults as defaultControls} from 'ol/control.js';

async function getData(url) {
    const response = await fetch(url);
    return response.json();
}

var baumlisteJson = "";
var imgHost = '';
if (typeof appBaumlistJson !== "undefined") {
    baumlisteJson = appBaumlistJson;
}
else {
    var url = 'http://baumkataster.dex/baumgrunddaten/';
    baumlisteJson = await getData(url);
    imgHost = 'http://baumkataster.dex/';
}

var lonCenter = 13.4229125;
var latCenter = 50.7944318;
var zoom = 10;
if (typeof lonCenterApp !== "undefined") {
    lonCenter = lonCenterApp;
}
if (typeof latCenterApp !== "undefined") {
    latCenter = latCenterApp;
}
if (typeof zoomApp !== "undefined") {
    zoom = zoomApp;
}

const source = new VectorSource();
const features = [];

for (var baum in baumlisteJson) {
    if (baumlisteJson[baum]["lon"] > 0 && baumlisteJson[baum]["lat"] > 0) {
        const coords = fromLonLat([baumlisteJson[baum]["lon"], baumlisteJson[baum]["lat"]]);
        const feature = new Feature({
            geometry: new Point(coords),
            baum_id: baumlisteJson[baum]["id"],
            baum_nr: baumlisteJson[baum]["baum_nr"],
            name: baumlisteJson[baum]["name"],
            baum_bild: baumlisteJson[baum]["bk_baum_bilder"],
            baumpate: baumlisteJson[baum]["baumpate_baum_grunddaten"],
            art_deutsch: baumlisteJson[baum]["art_deutsch"],
            art_wissenschaftlich: baumlisteJson[baum]["art_wissenschaftlich"],
        });
        var imgSrc = imgHost + '/theme/public/assets/img/marker-gelb.svg';
        if (baumlisteJson[baum]["baumpate_baum_grunddaten"] != "0") {
            imgSrc = imgHost + '/theme/public/assets/img/marker-gelb-pate.svg';
        }
        if (baumlisteJson[baum]["schutzstatus_baum_grunddaten"] == "2") {
            imgSrc = imgHost + '/theme/public/assets/img/marker.svg';
            if (baumlisteJson[baum]["baumpate_baum_grunddaten"] != "0") {
                imgSrc = imgHost + '/theme/public/assets/img/marker-pate.svg';
            }
        }
        var iconColor = "#FFFFFF";
        feature.setStyle(
            new Style({
                image: new Icon({
                    width: 82,
                    height: 140,
                    src: imgSrc,
                    scale: 0.3,
                }),
            })
        );
        features.push(feature);
    }
}
source.addFeatures(features);

const mapIcons = new VectorLayer({
    source: source,
});


const element = document.getElementById('popup');
const content = document.getElementById('popup-content');
const closer = document.getElementById('popup-closer');


const popup = new Overlay({
    element: element,
    positioning: 'bottom-center',
    stopEvent: true,
});
//map.addOverlay(popup);

const map = new Map({
    controls: defaultControls().extend([new FullScreen()]),
    interactions: defaults({dragPan: false, mouseWheelZoom: false}).extend([
        new DragPan({
            condition: function (event) {
                return this.getPointerCount() === 2 || platformModifierKeyOnly(event);
            },
        }),
        new MouseWheelZoom({
            condition: platformModifierKeyOnly,
        }),
    ]),
    target: 'map',
    layers: [
        new TileLayer({
            source: new OSM(),
        }),
        mapIcons,
    ],
    overlays: [popup],
    view: new View({
        center: fromLonLat([lonCenter, latCenter]),
        zoom: zoom,
    }),
});



let popover;

function disposePopover() {
    if (popover) {
        popover.dispose();
        popover = undefined;
    }
}

// display popup on click
map.on('click', function (evt) {
    const feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
        return feature;
    });
    disposePopover();
    if (!feature) {
        popup.setPosition(undefined);
        closer.blur();
        return;
    }
    popup.setPosition(evt.coordinate);
    var htmlImg = '';
    if (feature.get('baum_bild') !== "") {
        htmlImg = '<img src="' + imgHost + '/media/osm-preview-img/' + feature.get('baum_bild') + '" class="osmPreviewImg" alt="' + feature.get('name') + '">';
    }
    content.innerHTML = '' +
        '<a href="/baumliste/baum/?bid=' + feature.get('baum_id') + '" class="osmPopup">' +
        '<h2><b>' + feature.get('baum_nr') + '</b> ' + feature.get('name') + '</h2>' +
        htmlImg +
        '<p>' +
        'Art: ' + feature.get('art_deutsch') + ' [' + feature.get('art_wissenschaftlich') + ']<br>' +
        '<span class="link">Zur Detailseite</span>' +
        '</p>';
        '</a>';

});

// Close the popup when the map is moved
map.on('movestart', disposePopover);


// Popup Schliessen
closer.onclick = function () {
    popup.setPosition(undefined);
    closer.blur();
    return false;
};
